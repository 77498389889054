// Packages
import React from "react";
import { graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	lautenschlagerLogo: {
		publicURL: string;
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Impressum"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container>
				<div className="prose">
					<h1>Impressum</h1>

					<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
					<p>
						Daniel Thiel
						<br />
						Friedrich-Ebert-Stra&szlig;e 143
						<br />
						34119 Kassel
					</p>

					<h2>Kontakt</h2>
					<p>
						Telefon: 0160 620 15 95
						<br />
						E-Mail: anfragen@daniel-thiel.com
					</p>

					<h2>Aufsichtsbeh&ouml;rde</h2>
					<p>
						IHK Kassel
						<br />
						Kurf&uuml;rstenstra&szlig;e 9, 34117 Kassel
					</p>
					<p>
						<a href="https://www.ihk-kassel.de/" target="_blank" rel="noopener noreferrer">
							https://www.ihk-kassel.de/
						</a>
					</p>

					<h2>Redaktionell verantwortlich</h2>
					<p>
						Daniel Thiel
						<br />
						Friedrich-Ebert-Stra&szlig;e 143, 34117 Kassel
					</p>

					<h2>EU-Streitschlichtung</h2>
					<p>
						Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
						<a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
							https://ec.europa.eu/consumers/odr/
						</a>
						.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
					</p>

					<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
					<p>
						Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
						Verbraucherschlichtungsstelle teilzunehmen.
					</p>
				</div>
			</Container>
			<Container>
				<div className="mx-auto my-16 mt-3 max-w-xl bg-gray-100 p-2 text-center">
					<p className="mb-1 text-xs">Website erstellt von:</p>
					<a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
						<img
							src={data.lautenschlagerLogo.publicURL}
							title="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							alt="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							className="mx-auto"
						/>
						<br />
						Webdesigner Kassel - Lautenschlager Marketing & Entwicklung
					</a>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		lautenschlagerLogo: file(relativePath: { eq: "webdesign-kassel-lautenschlager-logo.png" }) {
			publicURL
		}
	}
`;
